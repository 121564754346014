import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast, ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import ApiUser from '@/api/user';
import { biConstants, EWebsiteTabNames } from '@/bi/bi-constants';
import { viewWebsiteTabsBiEvents } from '@/bi/events/view-website-tabs-bi-events';
import CONSTANTS from '@/common/constants';
import { CURRENT_ENV_CURRENCY } from '@/common/constants/web3';
import useToast from '@/hooks/use-toast';

import TxRow from './tx-row';

const CURRENT_ENV_CURRENCY_GAMES = 'SLAM';

const HistoryPage = ({ app }) => {
  console.log('🚀 ~ HistoryPage ~ app:', app);
  const itemsPerPage = 20;
  const { showToast } = useToast();
  const [transactionHistory, setTransactionHistory] = useState(null);
  const [isFilterTabOpen, setFilterTab] = useState(false);
  const [active, setActive] = useState('transfer');
  const [hasMore, setHasMore] = useState(true);
  // Here we are using the state for page number
  const [pageNumber, setPageNumber] = useState(0);
  // State for end message when we are at the end of page
  const [endMessage, setEndMessage] = useState('');
  useEffect(() => {
    // Fetch items whenver we scroll or at the start.
    console.log(pageNumber, 'pageNumber');
    if (active === 'games') {
      getTransactionforGames(pageNumber);
      return;
    } else if (active === 'transfer') {
      getTransactionforTransfer(pageNumber);
      return;
    }
  }, [pageNumber]);
  const handlePageClick = () => {
    //Method to increment the page number whenever user scrolls to the bottom
    console.log(`User requested page number which is offset ${pageNumber}`);
    setPageNumber(pageNumber + 1);
  };
  useEffect(() => {
    viewWebsiteTabsBiEvents.viewWebsite(EWebsiteTabNames.HISTORY);
    viewWebsiteTabsBiEvents.userIsOnSomeTab(EWebsiteTabNames.HISTORY);
  }, []);
  const getTransactionforGames = (value) => {
    toast.dismiss();
    const userData = localStorage.getItem(
      biConstants.BI_LOCAL_STORAGE_KEYS.USER_PROFILE,
    );
    const user = JSON.parse(userData);
    if (!user) {
      return;
    }
    ApiUser.getGameHistory(
      user.appUser.user.id,
      CURRENT_ENV_CURRENCY !== CURRENT_ENV_CURRENCY_GAMES
        ? CURRENT_ENV_CURRENCY + ',' + CURRENT_ENV_CURRENCY_GAMES
        : CURRENT_ENV_CURRENCY_GAMES,
      value,
      itemsPerPage,
    )
      .then((res) => {
        if (res.length === 0 || res.length < itemsPerPage) {
          setEndMessage(
            res.length === 0
              ? CONSTANTS.END_OF_TABLE_EMPTY_TEXT
              : CONSTANTS.END_OF_TABLE_TEXT,
          );
          setHasMore(false);
        }
        setTransactionHistory(
          transactionHistory !== null && value !== 0
            ? transactionHistory.concat(res)
            : res,
        );
      })
      .catch((err) => {
        setTransactionHistory([]);
        showToast('Invalid game history request!', null, 'error');
        console.error('History/history.js history\n', err);
      });
  };
  const getTransactionforTransfer = (value) => {
    toast.dismiss();
    const userData = localStorage.getItem(
      biConstants.BI_LOCAL_STORAGE_KEYS.USER_PROFILE,
    );
    const user = JSON.parse(userData);
    if (!user) {
      return;
    }
    ApiUser.getTransactionHistory(user.appUser.user.id, value, itemsPerPage)
      .then((res) => {
        if (res.length === 0 || res.length < itemsPerPage) {
          setEndMessage(
            res.length === 0
              ? CONSTANTS.END_OF_TABLE_EMPTY_TEXT
              : CONSTANTS.END_OF_TABLE_TEXT,
          );
          setHasMore(false);
        }
        setTransactionHistory(
          transactionHistory !== null && value !== 0
            ? transactionHistory.concat(res)
            : res,
        );
      })
      .catch((err) => {
        setTransactionHistory([]);
        showToast('Invalid request', null, 'error');
        console.error('ApiUser.getTransaction : history\n', err);
      });
  };

  function filterToggle() {
    document.getElementById('collec-sidebar').classList.toggle('openFilterBar');
    setFilterTab(!isFilterTabOpen);
  }

  const handleNavigation = (value) => {
    setHasMore(true);
    setEndMessage('');
    setTransactionHistory(null);
    setPageNumber(0);
    setActive(value);
    if (value === 'games' && pageNumber === 0) {
      getTransactionforGames(0);
      return;
    } else if (value === 'transfer' && pageNumber === 0) {
      getTransactionforTransfer(0);
      return;
    }
  };

  return (
    <>
      <ToastContainer theme="dark" limit={1} />
      <div className="collectibles-main-wrp">
        <div
          className="collectibles-main-row d-flex d-blockMobile"
          id="collectibles-main-page"
        >
          <div className="collectibles-side-bar" id="collec-sidebar">
            <h4 className="col-6 games-heading no-white-space">
              Transaction History
            </h4>
            <ul className="nav nav-tabs">
              <li
                className={active === 'transfer' ? 'active' : null}
                onClick={() => handleNavigation('transfer')}
              >
                <a data-toggle="tab" href="#all">
                  Transfer
                </a>
              </li>
              <li
                className={active === 'games' ? 'active' : null}
                onClick={() => handleNavigation('games')}
              >
                <a data-toggle="tab" href="#all">
                  Game
                </a>
              </li>
            </ul>
          </div>

          <div
            className="filter-button open"
            id="nav-toggle"
            onClick={() => filterToggle()}
          >
            <button
              className="filter"
              style={{ color: isFilterTabOpen ? '#ff006e' : '#ffffff' }}
            >
              {!isFilterTabOpen && <span className="material-icons">tune</span>}
              {!isFilterTabOpen ? 'Filter' : 'Close'}
            </button>
          </div>

          <div className="collectibles-right-bar">
            <div className="tab-content-champs">
              <div className="tab-pane active" id="champTab_1">
                <div className="nft-champ-main-rows">
                  <div className="history-table">
                    <div
                      className="table-responsive"
                      style={{ marginBottom: '50px' }}
                    >
                      <InfiniteScroll
                        dataLength={
                          transactionHistory &&
                          transactionHistory.length > 0 &&
                          transactionHistory.length
                        }
                        next={
                          transactionHistory &&
                          transactionHistory.length > 0 &&
                          handlePageClick
                        }
                        hasMore={hasMore}
                        loader={
                          <h4 style={{ textAlign: 'center' }}>Loading...</h4>
                        }
                        endMessage={
                          <p style={{ textAlign: 'center' }}>
                            <strong>{endMessage}</strong>
                          </p>
                        }
                      >
                        <table>
                          <thead>
                            <tr>
                              <th>
                                <span className="empty-space"></span>Transaction
                                ID
                              </th>
                              <th>Date/Time</th>
                              <th>Status</th>
                              <th>Description</th>
                              <th>Amount</th>
                              <th>Block Scan URL</th>
                            </tr>
                          </thead>

                          <tbody>
                            {transactionHistory &&
                              transactionHistory.length > 0 &&
                              transactionHistory.map((item, index) => {
                                return (
                                  <TxRow
                                    key={
                                      active === 'games'
                                        ? item.transactionId +
                                          item.timestamp +
                                          '' +
                                          index
                                        : item.id + '' + index
                                    }
                                    item={item}
                                    active={active}
                                  />
                                );
                              })}
                          </tbody>
                        </table>
                      </InfiniteScroll>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab-pane" id="champTab_2"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HistoryPage;
