import copy from 'copy-to-clipboard';
import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip';

import { ECtaNames, EWebsiteTabNames } from '@/bi/bi-constants';
import { commonBiEvents } from '@/bi/events/common-bi-events';

function CopyToClipBoard({ text }) {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  function copyToClipboard() {
    commonBiEvents.userClicksOnSomeCta(
      EWebsiteTabNames.ACCOUNT_BALANCE_WALLET,
      ECtaNames.COPY_TO_CLIPBOARD,
    );
    if (!text) return null;
    copy(text);
    setTooltipVisible(true);
    setTimeout(() => {
      setTooltipVisible(false);
    }, 2000);
  }

  return (
    <div className="copy-action">
      <button
        className="d-flex copy-btn align-items-center justify-content-center"
        data-tooltip-id="copied"
        onClick={copyToClipboard}
        type="button"
      >
        <span className="material-icons">content_copy</span>
      </button>
      <Tooltip id="copied" content="Copied!" isOpen={tooltipVisible} />
    </div>
  );
}

export default CopyToClipBoard;
