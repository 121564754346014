import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useToast = () => {
  const id = 'toast' + Date.now();
  const showToast = (value, config, type) => {
    toast.clearWaitingQueue();
    config = config || {};
    const options = {
      position: 'top-right',
      autoClose: 3000,
      closeOnClick: true,
      ...config,
    };

    if (type === 'error')
      return toast.error(value, options, {
        toastId: id,
      });
    if (type === 'warning') return toast.warn(value, options);
    if (type === 'success') return toast.success(value, options);
    if (type === 'info') return toast.info(value, options);
    toast.dismiss();
    return toast(value, options);
  };

  const ToastComponent = () => <ToastContainer theme="dark" limit={1} />;

  return {
    showToast,
    ToastComponent,
  };
};

export default useToast;
