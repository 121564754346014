import { StaticImage } from 'gatsby-plugin-image';
import React, { useMemo } from 'react';

import bingoLogo from '@/assets/images/game-logos/bingo.png';
import blackjackLogo from '@/assets/images/game-logos/blackjack.png';
import carromLogo from '@/assets/images/game-logos/carrom.png';
import dartsLogo from '@/assets/images/game-logos/darts.png';
import solitaireLogo from '@/assets/images/game-logos/solitaire.png';
import super8ballLogo from '@/assets/images/game-logos/super8ball.png';
import trickshotLogo from '@/assets/images/game-logos/trickshot.png';
import CONSTANTS from '@/common/constants';
import utils from '@/common/utils';
import CopyToClipBoard from '@/components/copy-to-clipboard';

const transactionType = {
  DEBIT: 'DEBIT',
  CREDIT: 'CREDIT',
};

const transactionTypeLabels = {
  CREDIT: 'Deposit',
  DEBIT: 'Withdraw',
};

const GAME_LOGOS = {
  'onjoyride.jrxsolitaireblitz': solitaireLogo,
  'onjoyridehpg.pool': trickshotLogo,
  'onjoyride.21blitz': blackjackLogo,
  'onjoyridehpg.carrom': carromLogo,
  'onjoyridehpg.8BPool': super8ballLogo,
  'onjoyriderio.darts': dartsLogo,
  'onjoyride.jrxbingoblitzCrypt': bingoLogo,
};

function TxRow({ item, active }) {
  /** @param timestamp in seconds */
  const getDate = (timestamp, value) => {
    if (!timestamp) return '-';
    let date = new Date(timestamp);
    if (value === 'date') {
      date =
        date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
      return date;
    } else if (value === 'hour') {
      date =
        (date.getHours() + '').padStart(2, 0) +
        ':' +
        (date.getMinutes() + '').padStart(2, 0) +
        ' ' +
        (date.getHours() > 11 ? 'PM' : 'AM');
      return date;
    } else {
      date =
        date.getDate() +
        '/' +
        (date.getMonth() + 1) +
        '/' +
        date.getFullYear() +
        ' ' +
        (date.getHours() + '').padStart(2, 0) +
        ':' +
        (date.getMinutes() + '').padStart(2, 0) +
        (date.getHours() > 11 ? 'PM' : 'AM');
      return date;
    }
  };

  const dateLine1 = useMemo(() => {
    if (!item) return '-';
    if (active === 'games') {
      return getDate(item.timestamp, 'date');
    } else {
      if (item.status === 'PENDING') {
        return '-';
      } else {
        if (item.type === transactionType.DEBIT) {
          return getDate(item.createdAt, 'date');
        } else {
          return getDate(item.time, 'date');
        }
      }
    }
  }, [item]);

  const dateLine2 = useMemo(() => {
    if (!item) return '-';
    if (active === 'games') {
      return getDate(item?.timestamp, 'hour');
    } else {
      if (item.status === 'PENDING') {
        return '-';
      } else {
        if (item.type === transactionType.DEBIT) {
          return getDate(item?.createdAt, 'hour');
        } else {
          return getDate(item?.time, 'hour');
        }
      }
    }
  }, [item]);

  const chainTxId = useMemo(() => {
    // for transfer txs, for debit its bridgeTransactionId and for credit its transactionId
    // for games txs, there will be blockchainTransactionId and transactionId
    return (
      item.blockchainTransactionId ??
      item.transactionId ??
      item.bridgeTransactionId ??
      ''
    );
  }, [item]);

  const amount = useMemo(() => {
    let amount = '-';
    if (item.type === transactionType.DEBIT) {
      amount = item.originalAmount + item.withdrawProcesingFee;
    } else {
      amount = item.amount;
    }
    return amount;
  }, [item]);

  return (
    <>
      <tr>
        <td>
          <div className="transaction-id">
            <div className="media">
              <div className="id-icon">
                {active === 'transfer' && (
                  <StaticImage
                    src="../../assets/images/rally-token@3x.png"
                    alt=""
                    layout="fixed"
                    width={43}
                  />
                )}
                {active === 'games' && GAME_LOGOS[item.appId] ? (
                  <img src={GAME_LOGOS[item.appId]} width={43} alt="" />
                ) : null}
              </div>
              <div className="media-body d-flex copyadrs-body">
                <h5 style={{ marginRight: '9px' }} className="mt-0">
                  {utils.shortenAddress(chainTxId) || '-'}
                </h5>
                {chainTxId && <CopyToClipBoard text={chainTxId} />}
              </div>
            </div>
          </div>
        </td>
        <td>
          <span className="date-time">
            {dateLine1}
            <br />
            <small>{dateLine2}</small>
          </span>
        </td>
        <td>
          <span
            className={
              item && active === 'games'
                ? `status ${item?.blockchainStatus?.toLowerCase()}`
                : `status ${item?.status?.toLowerCase()}`
            }
          >
            {item && active === 'games'
              ? item?.blockchainStatus?.toLowerCase()
              : item?.status?.toLowerCase()}
          </span>
        </td>
        <td>
          <span style={{ textTransform: 'capitalize' }} className="type">
            {active === 'games'
              ? item?.description
              : transactionTypeLabels[item.type] ||
                item.type?.toLowerCase() ||
                ''}
          </span>
        </td>
        <td>
          <span className="amount">
            <strong>{amount}</strong>{' '}
            <span className="rlytbl">{item.walletCurrency}</span>
          </span>
        </td>
        <td>
          {chainTxId &&
          ((active === 'transfer' && item.type === transactionType.CREDIT) ||
            active === 'games') ? (
            <a
              className="tbl_link blockscan-url"
              href={`${CONSTANTS.WEB3_CONFIG.BLOCKSCAN_URL}${chainTxId}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              {CONSTANTS.WEB3_CONFIG.BLOCKSCAN_URL + chainTxId}
            </a>
          ) : chainTxId &&
            active === 'transfer' &&
            item.type === transactionType.DEBIT ? (
            <a
              className="tbl_link blockscan-url"
              href={`${CONSTANTS.WEB3_CONFIG.ETHER_BLOCKSCAN_URL}${chainTxId}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              {CONSTANTS.WEB3_CONFIG.ETHER_BLOCKSCAN_URL + chainTxId}
            </a>
          ) : (
            '-'
          )}
        </td>
      </tr>
    </>
  );
}

export default TxRow;
