import React, { useEffect } from 'react';

import '@/assets/scss/racket-rampage.scss';
import utils from '@/common/utils';
import { withDashboardLayout } from '@/components/layout/dashboard-layout';
import { useAuth } from '@/context/auth-context';
import { HistoryPage } from '@/features/history';
import NavPills from '@/features/racket-rampage/nav-pills';
import RewardEvent from '@/features/racket-rampage/reward-event';

const RRHistory = () => {
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      utils.getAuthUrl();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="collectibles-main-wrp racket-rampage">
        <RewardEvent />
        <NavPills app="marketplace" />
        <div className="nav-border-top mt-20"></div>
        <HistoryPage app="racket-rampage" />
      </div>
    </>
  );
};

export default withDashboardLayout(RRHistory);

export function Head() {
  return <title>Superchamps Wallet | Racket Rampage</title>;
}
